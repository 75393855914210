<template>
  <div class="mobileGatewayParam">
    <mHead :name="'超级主机'" :add="'/mobile/ProGateway'" :adds="'/mobile/ProGatewayParam'" :color="false" />
    <div class="param">
      <div class="title">相关参数</div>
      <div class="text">
        <div class="left">产品型号:</div>
        <div class="right">LSG03A</div>
      </div>
      <div class="text">
        <div class="left">产品尺寸:</div>
        <div class="right">100×100×20mm</div>
      </div>
      <div class="text">
        <div class="left">额定输入:</div>
        <div class="right">DC5V/2A</div>
      </div>
      <div class="text">
        <div class="left">网络接口:</div>
        <div class="right">RJ45</div>
      </div>
      <div class="text">
        <div class="left">操作系统:</div>
        <div class="right">Linux</div>
      </div>

      <div class="text">
        <div class="left">工作温度:</div>
        <div class="right">0°C-40°C</div>
      </div>
      <div class="text">
        <div class="left">工作湿度:</div>
        <div class="right">0%-95%RH，无冷凝</div>
      </div>
      <div class="text">
        <div class="left">通信协议:</div>
        <div class="right">自定义LSSN2.0</div>
      </div>

      <div class="text">
        <div class="left">工作频段:</div>
        <div class="right">433.0MHz~453.0MHz</div>
      </div>

      <div class="text">
        <div class="left">无线发射功率:</div>
        <div class="right">＜10dBm</div>
      </div>
    </div>
    <div class="paramDe">
      <div class="txt">以Linux系统为核心，采用BOT智能物联网控制平台，实现场景模式解析、无线设备控制和云端互联，具备极高的灵活性和定制化能力，大幅度减低布线和安装成本。</div>
      <div class="txt">24小时不间断检测无线设备稳定状态，其核心处理器能快速匹配并连接已对接的智能硬件设备，能对接市面上所有的智能硬件设备产品。</div>
      <div class="txt">采用嵌入式操作系统，硬件配置强大，可单独控制每一个设备，也支持“广播”控制功能，可以广播群发的方式控制多个设备同步动作。</div>
      <div class="txt">支持离线端对端对接，即使网络不通也不影响房内所有智能设备的正常使用。接口支持5G设备的对接，主机覆盖室内建筑面积100-200㎡。</div>
      <div class="data">
        <img src="../../assets/mobile/dataImg.png" class="dataImg" />
      </div>
    </div>
  </div>
</template>

<script>
import mHead from "../../components/MobileHead.vue";
export default {
  components: {
    mHead
  },
  data() {
    return {};
  }
};
</script>
<style scoped lang="less">
.mobileGatewayParam {
  background-color: #fff;
  .param {
    margin: 0 0.3rem;
    padding-bottom: 0.26rem;
    border-bottom: 1px solid #333;
    .title {
      margin-top: 0.8rem;
      font-size: 0.48rem;
      line-height: 0.48rem;
      font-weight: bold;
      color: #ff9000;
    }
    .text {
      margin-top: 0.36rem;
      display: flex;
      font-size: 0.24rem;
      line-height: 0.24rem;
      font-weight: 400;
      color: #333333;
      &:first-of-type {
        margin-top: 0.59rem;
      }
      .left {
        width: 1.56rem;
        text-align: right;
      }
      .right {
        margin-left: 0.6rem;
      }
    }
  }
  .paramDe {
    margin-top: 0.3rem;
    padding: 0px 0.3rem;
    .txt {
      font-size: 0.24rem;
      font-weight: 400;
      color: #333333;
      line-height: 0.4rem;
      text-align: justify;
    }
    .data {
      width: 6.45rem;
      margin-top: 0.39rem;
      padding-bottom: 0.8rem;
      display: flex;
      .dataImg {
        width: 100%;
        height: 0.18rem;
      }
    }
  }
}
</style>
